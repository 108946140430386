import { blue, neutral, primary, red } from '../themes/colors';

export const buttonVariants = [
	'white',
	'default',
	'primary',
	'secondary',
	'danger',
	'ghost',
	'link',
	'gradient',
] as const;

export type ButtonVariant = typeof buttonVariants[number];

type StateColorsDefinition = {
	default: string;
	hover: string;
	active: string;
	disabled: string;
}

export type ButtonColorDefinition = {
	opacity: number;
	background: StateColorsDefinition;
	color: StateColorsDefinition;
	border: StateColorsDefinition | null;
}

const sameColorEveryState = (color: string) : StateColorsDefinition => ({
	default: color,
	hover: color,
	active: color,
	disabled: color,
});

export const ButtonColors: {[key in ButtonVariant]: ButtonColorDefinition} = {
	default: {
		opacity: 0.1,
		background: {
			default: neutral.white,
			hover: neutral.white,
			active: neutral.white,
			disabled: neutral.white,
		},
		color: {
			default: primary['800'],
			hover: blue['400'],
			active: primary['500'],
			disabled: primary['800'],
		},
		border: {
			default: neutral['200'],
			hover: blue['400'],
			active: primary['500'],
			disabled: primary['500'],
		},
	},
	white: {
		opacity: 0.1,
		background: {
			default: neutral.white,
			hover: neutral.white,
			active: neutral.white,
			disabled: neutral.white,
		},
		color: {
			default: primary['800'],
			hover: blue['400'],
			active: primary['500'],
			disabled: primary['800'],
		},
		border: {
			default: neutral['200'],
			hover: blue['400'],
			active: primary['500'],
			disabled: primary['500'],
		},
	},
	primary: {
		opacity: 0.5,
		background: {
			default: primary['500'],
			hover: primary['400'],
			active: primary['700'],
			disabled: primary['500'],
		},
		color: sameColorEveryState('#FFFFFF'),
		border: {
			default: primary['500'],
			hover: primary['400'],
			active: primary['700'],
			disabled: primary['500'],
		},
	},
	secondary: {
		opacity: 0.5,
		background: {
			default: primary['50'],
			hover: neutral['200'],
			active: neutral['400'],
			disabled: primary['50'],
		},
		color: sameColorEveryState(primary['800'],),
		border: {
			default: primary['50'],
			hover: neutral['200'],
			active: neutral['400'],
			disabled: primary['50'],
		},
	},
	danger: {
		opacity: 0.5,
		background: {
			default: red['25'],
			hover: red['50'],
			active: red['50'],
			disabled: red['25'],
		},
		color: sameColorEveryState(red['500']),
		border: {
			default: red['100'],
			hover: red['100'],
			active: red['300'],
			disabled: red['100'],
		},
	},
	ghost: {
		opacity: 0.5,
		background: {
			default: 'transparent',
			hover: 'transparent',
			active: 'transparent',
			disabled: 'transparent'
		},
		color: sameColorEveryState(blue['400']),
		border: {
			default: 'transparent',
			hover: 'transparent',
			active: 'transparent',
			disabled: 'transparent'
		},
	},
	link: {
		opacity: 0.5,
		background: {
			default: 'transparent',
			hover: 'transparent',
			active: 'transparent',
			disabled: 'transparent'
		},
		color: sameColorEveryState(blue['400']),
		border: {
			default: 'transparent',
			hover: 'transparent',
			active: 'transparent',
			disabled: 'transparent'
		},
	},
	gradient: {
		opacity: 0.5,
		background: {
			default: 'linear-gradient(90deg, #2B88FF 0%, #005FD7 100%)',
			// eslint-disable-next-line max-len
			hover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(90deg, #0261D8 0%, #2B88FF 100%)',
			// eslint-disable-next-line max-len
			active: 'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(90deg, #0261D8 0%, #2B88FF 100%)',
			disabled: 'linear-gradient(90deg, rgba(43, 136, 255, 0.50) 0%, rgba(0, 95, 215, 0.50) 100%)',
		},
		color: {
			default: '#FFF',
			hover: '#FFF',
			active: '#FFF',
			disabled: '#FFF'
		},
		border: null
	}

};
