export const buttonSizes = [
	'default',
	'small',
	'link',
] as const;

export type ButtonSize = typeof buttonSizes[number];

export type ButtonSizeDefinition = {
	lineHeight: number;
	paddingVertical: number;
	paddingHorizontal: number;
	paddingNoLabel: number;
	fontSize: number;
	gap: number;
};

export const ButtonSizes: {[key in ButtonSize]: ButtonSizeDefinition} = {
	default: {
		lineHeight: 18,
		paddingVertical: 10,
		paddingHorizontal: 16,
		paddingNoLabel: 10,
		fontSize: 14,
		gap: 6,
	},
	small: {
		lineHeight: 18,
		paddingVertical: 6,
		paddingHorizontal: 10,
		paddingNoLabel: 6,
		fontSize: 14,
		gap: 6,
	},
	link: {
		lineHeight: 18,
		paddingVertical: 2,
		paddingHorizontal: 6,
		paddingNoLabel: 2,
		fontSize: 14,
		gap: 6,
	},
};
