import * as PIXI from 'pixi.js-legacy';
import { CaptionPositioningFunction, Captions,
	Contestant, ContestAppOptions, ContestAppSize, Position, ProgressInContest } from './types';

const CAPTION_RATIO = 0.244813278008299;
const FONT_SHADOW_RATIO = 6;

export class CaptionsRenderer {
	options: ContestAppOptions;
	size: ContestAppSize;

	constructor(size: ContestAppSize, options: ContestAppOptions) {
		this.options = options;
		this.size = size;
	}

	renderCaptions(contestant: Contestant, progressInContest: ProgressInContest, position: Position,
		spineHeight: number, spineWidth: number): Captions {
		const nameCaption = this.renderText(contestant.name, spineHeight);
		const scoreCaption = this.renderText(
			progressInContest.formattedValue || `${progressInContest.progress}%`, spineHeight);

		const maxWidth = Math.max(nameCaption.width, scoreCaption.width);

		this.positionCaption(
			nameCaption,
			position,
			maxWidth,
			spineWidth,
			this.options.captionPositioner,
			this.options.captionAnchor
		);

		this.positionCaption(
			scoreCaption,
			position,
			maxWidth,
			spineWidth,
			this.options.scorePositioner,
			this.options.scoreAnchor
		);

		return {
			nameCaption,
			scoreCaption
		};
	}

	updateCaptionsPosition(captions: Captions, position: Position, spineWidth: number) {
		this.positionCaption(
			captions.nameCaption,
			position,
			Math.max(captions.nameCaption.width, captions.scoreCaption.width),
			spineWidth,
			this.options.captionPositioner,
			this.options.captionAnchor
		);

		this.positionCaption(
			captions.scoreCaption,
			position,
			Math.max(captions.nameCaption.width, captions.scoreCaption.width),
			spineWidth,
			this.options.scorePositioner,
			this.options.scoreAnchor
		);
	}

	// eslint-disable-next-line max-params
	positionCaption(
		caption: PIXI.Text,
		position: Position,
		textWidth: number,
		spineWidth: number,
		captionPositioner: CaptionPositioningFunction,
		captionAnchor: CaptionPositioningFunction,
	) {

		const captionPosition = captionPositioner(this.size, position, textWidth, spineWidth);
		const anchor = captionAnchor(this.size, position, textWidth, spineWidth);

		caption.position.set(
			captionPosition.x,
			captionPosition.y
		);

		caption.anchor.set(
			anchor.x,
			anchor.y
		);
	}

	renderText(text: string, spineHeight: number) {
		const captionHeight = spineHeight * CAPTION_RATIO;

		const caption = new PIXI.Text(text, {
			fontFamily: 'Red Hat Display',
			fontSize: captionHeight,
			fontWeight: '700',
			fill: 0xffffff,
			strokeThickness: captionHeight / FONT_SHADOW_RATIO
		});

		return caption;
	}
}
