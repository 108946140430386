import * as PIXI from 'pixi.js-legacy';
import { CharacterAnimation, CharacterMode, HumanAvatar } from '../types';
import { createAstronautInShipSpine,
	createAstronautSpine, createHumanInShipSpine, createHumanSpine } from './pixiUtilities';
import { Contestant, PositionWithScale } from './types';
import { mapAnimationToName } from '../mapper';

const figureCreateFunctions = {
	human: createHumanSpine,
	astronaut: createAstronautSpine,
};

const shipCreateFunctions = {
	human: createHumanInShipSpine,
	astronaut: createAstronautInShipSpine,
};

export const characterRenderer = (
	app: PIXI.Application, 
	contestant: Contestant, 
	mode: CharacterMode,
	position?: PositionWithScale) => {

	const createFunc = mode === 'figure' ? figureCreateFunctions[contestant.avatarSettings.kind]
		: shipCreateFunctions[contestant.avatarSettings.kind];

	return createFunc(app,
		contestant.avatarSettings[contestant.avatarSettings.kind] as HumanAvatar, position);
};

export const animationsHandler = {
	getMoveAnimation: (animation: CharacterAnimation, contestant: Contestant) => {
		return mapAnimationToName(contestant.avatarSettings.kind, animation);
	}
};
