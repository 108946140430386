import React, { useCallback, useContext, useMemo } from 'react';
import { DropdownOption } from './types';
import { Box, StyledDropdownBox } from './Box';
import { useDropdownLogic } from './useDropdownLogic';
import { createPortal } from 'react-dom';
import { Menu } from './Menu';
import { IconName } from '../icon';
import { DropdownParentContext } from './DropdownParentContext';
import { keyCodes } from '../utils/keyCodes';
import { useDebouncedInputState, useMergedRefs } from '../hooks';
import { ComponentWithCssSelector, withCssSelector } from '../shared/withCssSelector';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';
import { formInputSizes, FormInputSize } from '../utils/formInputSize';
import {
	DropdownPlaceholder, DropdownSearchIcon, DropdownTextInput, DropdownValue, DropdownValueIcon
} from './Dropdown.styled';

type DropdownProps<T extends React.Key> = {
	value: T | undefined,
	icon?: IconName,
	loading?: boolean,
	onClose?: () => void,
	onSelect: (id: T) => unknown,
	options: DropdownOption<T>[],
	restricted?: boolean,
	placeholder?: string,
	searchLabel?: string,
	loadingLabel?: string,
	searchable?: boolean,
	disabled?: boolean,
	hasError?: boolean,
	size?: FormInputSize,
};

function DropdownWithRef<T extends React.Key>({
	value,
	icon,
	loading,
	onClose: propsOnClose,
	onSelect,
	restricted,
	options = [],
	placeholder,
	searchLabel,
	loadingLabel,
	searchable,
	disabled,
	className,
	hasError,
	size = formInputSizes.default,
}: PropsWithClassName<DropdownProps<T>>, boxExternalRef: React.ForwardedRef<HTMLDivElement>) {
	const [
		searchInputValue, 
		searchPhrase,
		onSearchChange,
		forceSearchPhraseChange
	] = useDebouncedInputState();

	const onMenuClose = () => {
		forceSearchPhraseChange('');
		propsOnClose?.();
	};

	const {
		isOpen,
		styles,
		attributes,
		onClose,
		onOpen,
		buttonRef,
		menuRef,
	} = useDropdownLogic({ onMenuClose, sticky: true });

	const selectedItem = value ? options.find(option => option.value === value) : null;

	const onKeyUp = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.code === keyCodes.ESC) {
			onClose();
		}
	}, [onClose]);

	const searchMode = isOpen && searchable;

	const filteredOptions = useMemo(() => searchMode && searchPhrase ? options.filter((option) => 
		option.label.toLowerCase().includes(searchPhrase.toLowerCase())) : options, 
	[options, searchMode, searchPhrase]);

	const onValueSelected = (value: T) => {
		onSelect(value);
		onClose();
	};

	const { parent } = useContext(DropdownParentContext);

	const boxRef = useMergedRefs(buttonRef, boxExternalRef);

	return <>
		<Box
			ref={boxRef}
			loading={loading}
			size={size}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
			disabled={disabled}
			restricted={restricted}
			className={className}
			hasError={hasError}>
			{searchMode 
				? <>
					{!selectedItem && <DropdownSearchIcon icon='magnifying-glass' />}
					<DropdownTextInput
						value={searchInputValue}
						onChange={onSearchChange}
						autoFocus
						type='text'
						onKeyUp={onKeyUp}
						placeholder={selectedItem?.label || searchLabel || 'Search'} /> 
				</>
				: (selectedItem ? 
					<>
						{icon && <DropdownValueIcon icon={icon} />}
						<DropdownValue>
							{selectedItem.label}
						</DropdownValue>
					</> 
					: <DropdownPlaceholder>
						{loading ? (loadingLabel || 'Loading') : placeholder || 'Select'}
					</DropdownPlaceholder>)}
		
		</Box>
		{isOpen && createPortal(<Menu
			popperStyles={styles}
			popperAttributes={attributes}
			onSelect={onValueSelected}
			options={filteredOptions}
			ref={menuRef}
			value={value}
		/>, parent || document.body)}
	</>;
}

// eslint-disable-next-line max-len
export const Dropdown = withCssSelector(React.forwardRef(DropdownWithRef), StyledDropdownBox.toString()) as unknown as (<T extends React.Key>(
	props: PropsWithClassName<DropdownProps<T>> & { ref?: React.ForwardedRef<HTMLDivElement> }
  ) => ReturnType<typeof DropdownWithRef>) & ComponentWithCssSelector;

