import styled, { css } from 'styled-components';
import { Icon } from '../icon';
import { primary } from '../themes/colors';

export const ItemLabel = styled.span<{
    $disabled?: boolean,
    $danger?: boolean,
}>`
  flex: 1;
  font-size: 14px;
  text-align: left;
  color: ${({ $disabled, $danger, theme }) => $disabled ? theme.form.dropdown.menu.disabled.color : 
		($danger ? theme.form.dropdown.menu.danger.color : theme.form.label.color)};
`;
export const MenuItemRoot = styled.div<{
    $selected?: boolean,
    $disabled?: boolean,
    $danger?: boolean,
}>`
  padding: 8px 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  border: none;
  background: ${props => props.theme.form.dropdown.menu.default};

  ${Icon.cssSelector} {
    flex: 0 1 auto;
    width: 20px;
    height: 20px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.$danger ? props.theme.form.dropdown.menu.danger.color : primary['200']};
  }

  ${({ $disabled }) => !$disabled && css`
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background: ${props => props.theme.form.dropdown.menu.hover};
    }
  `};

  ${({ $selected }) => $selected && css`
    background: ${props => props.theme.form.dropdown.menu.selected};
  `};
`;

export const MenuRoot = styled.div`
  border-radius: 2px;
  box-shadow: ${props => props.theme.form.dropdown.menu.shadow};
  z-index: 45;
`;

export const MenuOverflow = styled.div`
  font-family: "Red Hat Display", sans-serif;
  box-sizing: border-box;
  background: #FFF;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.form.dropdown.chevron.color};
  max-height: 300px;
  overflow: auto;
`;
