import { ChangeEvent, useEffect, useState } from 'react';
import { DateObject, Value } from 'react-multi-date-picker';

import { Input } from '../form/input';
import { FormField, FormFieldsRow } from '../form';
import { FieldLabel } from '../form/field-label';
import {
	ActionButton, AddEndDatePlaceholder, AddNewButton,
	DateRangePickerWrapper,
	DateTimeControl,
	FormFieldSeparator
} from './DateRangePicker.styled';
import { Icon } from '../icon';

type CustomInputProps = {
    canRemoveEndDate: boolean;
    endDateHasError?: boolean,
    endDateObj: Value;
    highlightedEndDate?: boolean;
    highlightedStartDate: boolean;
    onChange: (date: Value[]) => void;
    onEndDateHighlight?: () => void;
    onStartDateHighlight: () => void;
    onTypeChange?: (state: boolean) => void;
    openCalendar: () => void;
    placeholder: string;
    showEndDate?: boolean,
    startDateHasError: boolean,
    startDateObj: Value;
    value: string;
}

export function DateRangeTimeControl({
	canRemoveEndDate,
	endDateHasError,
	endDateObj,
	highlightedEndDate = false,
	highlightedStartDate,
	onChange,
	onEndDateHighlight,
	onStartDateHighlight,
	onTypeChange,
	openCalendar,
	placeholder,
	showEndDate,
	startDateHasError,
	startDateObj,
	value,
}: CustomInputProps) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [dateStart = '', timeStart = '', _, dateEnd = '', timeEnd = ''] =
    value?.toString()?.split?.(' ') || [];

	const [localStart, setLocalStart] = useState<string>(timeStart);
	const [localEnd, setLocalEnd] = useState<string>(timeEnd);

	const onChangeTime = (time: string, date: Value, changeEndDate?: boolean) => {
		const [hour, minutes] = time?.split?.(':') || [];
		if (date !== null) {
			const change = (date instanceof DateObject ? date :
				new DateObject(date)).setHour(Number(hour)).setMinute(Number(minutes));
			onChange(changeEndDate ? [startDateObj, change] : [change, endDateObj]);
		}
	};

	const changeStartTime = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalStart(event.target.value);
		onChangeTime(event.target.value, startDateObj);
	};

	const changeEndTime = (event: ChangeEvent<HTMLInputElement>) => {
		setLocalEnd(event.target.value);
		onChangeTime(event.target.value, endDateObj, true);
	};

	const clearEndDate = () => {
		onTypeChange?.(false);
		onChange([startDateObj, null]);
	};

	const addEndDate = () => {
		onTypeChange?.(true);
		onChange([startDateObj, '']);
	};

	const onStartDateInputClick = () => {
		onStartDateHighlight?.();
		openCalendar();
	};

	const onEndDateInputClick = () => {
		onEndDateHighlight?.();
		openCalendar();
	};

	useEffect(() => {
		setLocalStart(timeStart);
		setLocalEnd(timeEnd);
	}, [timeStart, timeEnd]);

	return (
		<DateRangePickerWrapper>
			<FormFieldsRow>
				<FormField>
					<FieldLabel htmlFor="dateStart">{'Start'}</FieldLabel>
					<DateTimeControl $highlight={highlightedStartDate} $hasError={startDateHasError}>
						<Input
							readOnly
							id="dateStart"
							name="dateStart"
							value={dateStart}
							placeholder={placeholder}
							onFocus={onStartDateInputClick}
						/>
						<Input type="time" name={'startTime'} onChange={changeStartTime} value={localStart} />
					</DateTimeControl>
				</FormField>
				<FormFieldSeparator>
					<Icon icon={'minus'} />
				</FormFieldSeparator>
				{!showEndDate ? <AddEndDatePlaceholder>
					<AddNewButton onClick={addEndDate}><Icon icon={'plus'} /> {'Add end date'}</AddNewButton>
				</AddEndDatePlaceholder> : <FormField>
					<FieldLabel
						style={{ justifyContent: 'space-between' }}>{'End'} {canRemoveEndDate ? <ActionButton
							onClick={clearEndDate}
						>{'Remove'}</ActionButton> : null}</FieldLabel>
					<DateTimeControl $highlight={highlightedEndDate} $hasError={endDateHasError}>
						<Input
							readOnly
							id="dateEnd"
							name="dateEnd"
							value={dateEnd}
							placeholder={placeholder}
							onFocus={onEndDateInputClick}
						/>
						<Input type="time" name={'endTime'} onChange={changeEndTime} value={localEnd} />
					</DateTimeControl>
				</FormField>}
			</FormFieldsRow>
		</DateRangePickerWrapper>
	);
}
