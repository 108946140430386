import { useRef, useState } from 'react';
import DatePicker, { DateObject, DatePickerRef, Value } from 'react-multi-date-picker';
import { DateRangeTimeControl } from './DateRangeTimeControl';
import { Shortcuts } from './Shortcuts';
import { CustomDateRangePicker } from './DateRangePicker.styled';
import {
	DateValue,
	dayIndexes,
	defaultFormat,
	defaultPlaceholder,
	monthYearSeparator,
	separator
} from './DateRangePicker.props';

type RangeDatePickerOptions = {
	canRemoveEndDate?: boolean;
	endDateHasError?: boolean;
	startDateHasError?: boolean;
	showEndDate?: boolean;
}

type RangeDatePickerProps = {
	onChange: (start: DateValue, end: DateValue, date: Value[] | Value) => void;
	onToggle?: (state: boolean) => void;
	start: DateValue;
	end: DateValue;
	format?: string;
	placeholder?: string;
	options: RangeDatePickerOptions;
}

export function DateRangePicker({
	format = defaultFormat,
	placeholder = defaultPlaceholder,
	onChange,
	onToggle,
	start = null,
	end = null,
	options,
}: RangeDatePickerProps) {
	const {
		showEndDate = true,
		endDateHasError = false,
		startDateHasError = false,
		canRemoveEndDate = true
	} = options;
	const datePickerRef = useRef<DatePickerRef>();
	const [value, setValue] = useState<Value>(start !== null ? new DateObject(start) : start);
	const [values, setValues] = useState<Value[]>([
		start !== null ? new DateObject(start) : start,
		end !== null ? new DateObject(end) : end,
	]);

	const [rangeActive, setRangeActive] =
		useState(Boolean(start && end) || !canRemoveEndDate || showEndDate);

	const [highlightedStartDate, setHighlightOnStartDate] = useState(false);
	const [highlightedEndDate, setHighlightOnEndDate] = useState(false);

	const [startDate, endDate] = values;
	const onStartDateHighlight = () => {
		setHighlightOnStartDate(true);
		setHighlightOnEndDate(false);
	};

	const onEndDateHighlight = () => {
		setHighlightOnStartDate(false);
		setHighlightOnEndDate(true);
	};

	const handleRangeChange = (date: Value[], options?: { validatedValue: string | Array<string> }) => {
		if (options) {
			const onlyStartSelected = options.validatedValue.length === 1;
			if (onlyStartSelected) {
				setValue(date[0]);
				setValues([date[0], endDate || null]);
				onChange(date[0] ? date[0].toString() : null, endDate ? endDate.toString() : null, date);
			} else if (options.validatedValue.length === 2) {
				setValues(date);
				onChange(date[0] ? date[0].toString() : null, date[1] ? date[1].toString() : null, date);
				closeCalendar();
			}
		} else {
			setValue(date[0]);
			setValues(date);
			onChange(date[0] ? date[0].toString() : null, date[1] ? date[1].toString() : null, date);
		}
	};

	const handleChange = (date: Value) => {
		setValues([date, endDate]);
		setValue(date);
		onChange(date ? date.toString() : null, endDate ? endDate.toString() : null, date);

	};

	const closeCalendar = () => {
		datePickerRef?.current?.closeCalendar();
	};

	const onClose = () => {
		setHighlightOnStartDate(false);
		setHighlightOnEndDate(false);
	};

	const onPickerTypeChange = (state: boolean) => {
		onToggle?.(state);
		setRangeActive(state);
	};

	const onFocusedDateChange = (focusedDate: DateObject) => {
		if (rangeActive && startDate && endDate)
			if (highlightedStartDate) {
				const [start, end = null] = focusedDate > endDate ? [focusedDate] : [focusedDate, endDate];
				setValues([start, end]);
				setHighlightOnStartDate(false);
				setHighlightOnEndDate(true);
				onChange(start.toString(), end === null ? end : end.toString(), [start, end]);
			} else if (highlightedEndDate) {
				const [start, end] = focusedDate < startDate ? [focusedDate, startDate] : [startDate, focusedDate];
				setValues([start, end]);
				setHighlightOnEndDate(false);
				onChange(start.toString(), end.toString(), [start, end]);
				closeCalendar();
			}
	};

	if (!rangeActive) {
		return <CustomDateRangePicker>
			<DatePicker
				className="custom-date-range-picker"
				containerClassName="custom-container"
				value={value}
				onChange={handleChange}
				numberOfMonths={1}
				format={format}
				containerStyle={{
					width: '100%'
				}}
				onClose={onClose}
				highlightToday
				weekStartDayIndex={dayIndexes.monday}
				monthYearSeparator={monthYearSeparator}
				render={(currentValue, openCalendar) => <DateRangeTimeControl
					placeholder={placeholder}
					value={currentValue}
					openCalendar={openCalendar}
					startDateObj={startDate}
					startDateHasError={startDateHasError}
					endDateObj={endDate}
					onChange={handleRangeChange}
					onTypeChange={onPickerTypeChange}
					showEndDate={rangeActive}
					canRemoveEndDate={canRemoveEndDate}
					highlightedStartDate={highlightedStartDate}
					onStartDateHighlight={onStartDateHighlight}
				/>}
			/>
		</CustomDateRangePicker>;
	}

	return <CustomDateRangePicker>
		<DatePicker
			ref={datePickerRef}
			className="custom-date-range-picker"
			containerClassName="custom-container"
			value={values}
			range
			rangeHover
			showOtherDays
			onChange={handleRangeChange}
			numberOfMonths={2}
			format={format}
			highlightToday
			containerStyle={{
				width: '100%'
			}}
			onClose={onClose}
			onFocusedDateChange={onFocusedDateChange}
			weekStartDayIndex={dayIndexes.monday}
			monthYearSeparator={monthYearSeparator}
			render={(currentValue, openCalendar) => {
				const onlyStartSelected = currentValue.toString()?.split?.(separator).filter(v => v).length === 1;
				const change = onlyStartSelected && endDate ? `${currentValue} ~ ${endDate?.toString()}` : currentValue;
				return <DateRangeTimeControl
					placeholder={placeholder}
					value={change}
					openCalendar={openCalendar}
					startDateObj={startDate}
					endDateObj={endDate}
					showEndDate={rangeActive}
					onTypeChange={onPickerTypeChange}
					onChange={handleRangeChange}
					endDateHasError={endDateHasError}
					startDateHasError={startDateHasError}
					canRemoveEndDate={canRemoveEndDate}
					highlightedStartDate={highlightedStartDate}
					highlightedEndDate={highlightedEndDate}
					onStartDateHighlight={onStartDateHighlight}
					onEndDateHighlight={onEndDateHighlight}
				/>;
			}}
			plugins={[
				<Shortcuts
					key={'shortcuts'}
					onChange={handleRangeChange}
					// @ts-ignore
					position={'left'}
				/>,
			]}
		/>
	</CustomDateRangePicker>;
}
