import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { createPortal } from 'react-dom';
import { useMergedRefs } from '../hooks';
import { ComponentWithCssSelector, withCssSelector } from '../shared/withCssSelector';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';
import styled from 'styled-components';
import { useDropdownLogic } from '../dropdown/useDropdownLogic';
import { Button, ButtonVariant } from '../button';
import { DropdownParentContext } from '../dropdown';
import { MenuOverflow, MenuRoot } from '../dropdown/Menu.styled';
import { neutral } from '../themes/colors';
import { DropdownToggleMenuItem } from './DropdownToggleMenuItem';

const StyledDropdownToggleBox = styled.div``;

export const StyledMenuOverflow = styled(MenuOverflow)`
    min-width: 200px;
    border: 1px solid ${neutral['200']};
`;

export type DropdownToggleProps = {
	variant?: ButtonVariant;
	children: ReactElement|ReactElement[];
};

function DropdownToggleWithRef({
	variant = 'secondary',
	children,
	className,
}: PropsWithClassName<DropdownToggleProps>, boxExternalRef: React.ForwardedRef<HTMLDivElement>) {

	const {
		isOpen,
		styles,
		attributes,
		onToggle,
		buttonRef,
		menuRef,
	} = useDropdownLogic({ placement: 'bottom-end' });

	const boxRef = useMergedRefs(buttonRef, boxExternalRef);

	const { parent } = useContext(DropdownParentContext);

	const innerRef = React.useRef<HTMLDivElement>(null);
	const menuRefMerged = useMergedRefs<HTMLElement>(menuRef, innerRef);

	const childrenArray = React.Children.toArray(children).filter(Boolean);

	const options = childrenArray.map((child, index) => {
		if (React.isValidElement(child)) {
			return <DropdownToggleMenuItem key={index} {...child.props} />;
		}
		return child;
	});

	return <>
		<Button
			ref={boxRef}
			icon={'ellipsis-v'}
			variant={variant}
			onClick={onToggle}
			active={isOpen}
		/>

		{isOpen && createPortal(<MenuRoot
			ref={menuRefMerged}
			style={styles}
			{...attributes}>
			<StyledMenuOverflow className={className}>
				{options}
			</StyledMenuOverflow>
		</MenuRoot>, parent || document.body)}
	</>;
}

// eslint-disable-next-line max-len
export const DropdownToggle = withCssSelector(React.forwardRef(DropdownToggleWithRef), StyledDropdownToggleBox.toString()) as unknown as ((
	props: PropsWithChildren<PropsWithClassName<DropdownToggleProps>> & { ref?: React.ForwardedRef<HTMLDivElement> }
  ) => ReturnType<typeof DropdownToggleWithRef>) & ComponentWithCssSelector;
