import React, { CSSProperties, useLayoutEffect } from 'react';
import { DropdownOption } from './types';
import { MenuItem } from './MenuItem';
import _groupBy from 'lodash/groupBy';
import { GroupItem } from './GroupItem';
import { useMergedRefs } from '../hooks';
import { scrollParentToChild } from './scrollParentToChild';
import { ComponentWithCssSelector, withCssSelector } from '../shared/withCssSelector';
import { PropsWithClassName } from '../tabselector/PropsWithClassName';
import { MenuOverflow, MenuRoot } from './Menu.styled';

const noGroup = '__ungrouped__';

type MenuProps<T extends React.Key> = {
	value?: T | undefined;
	popperStyles: CSSProperties;
	popperAttributes: { [key: string]: string } | undefined;
	options: DropdownOption<T>[];
	noOptionsMessage?: string;
	onSelect?: (id: T) => void;
};

function MenuComponent<T extends React.Key>({
	value,
	popperStyles,
	popperAttributes,
	onSelect,
	options,
	noOptionsMessage = 'No options',
	className,
} : PropsWithClassName<MenuProps<T>>, menuRef: React.ForwardedRef<HTMLDivElement>) {

	const innerRef = React.useRef<HTMLDivElement>(null);

	const optionsByGroup = _groupBy(options, option => option.group || noGroup);
	
	const renderOption = (option: DropdownOption<T>) => <MenuItem 
		key={option.value.toString()}
		value={option.value.toString()}
		icon={option.icon}
		label={option.label}
		onSelect={option.onSelect || (() => onSelect?.(option.value))}
		selected={option.value === value}
		disabled={option.disabled}
	/>;

	const unGroupedOptions = optionsByGroup[noGroup] || [];

	useLayoutEffect(() => {
		if (value && innerRef.current) {
			const parent = innerRef.current.children[0] as HTMLElement;
			const element = innerRef.current.querySelector(`[data-value="${value}"]`) as HTMLElement;
			if (element) {
				scrollParentToChild(parent, element);
			}
		}
	}, [options, value]);

	const menuRefMerged = useMergedRefs<HTMLElement>(menuRef, innerRef);

	return (
		<MenuRoot
			ref={menuRefMerged}
			style={popperStyles}
			{...popperAttributes}>
			<MenuOverflow className={className}>
				{unGroupedOptions.length === 0 ? <MenuItem label={noOptionsMessage} disabled /> :
					<>
						{unGroupedOptions.map(renderOption)}
						{Object.entries(optionsByGroup).filter(([group]) => group !== noGroup)
							.map(([group, options]) => (
								<React.Fragment key={group}>
									<GroupItem label={group} />
									{options.map(renderOption)}
								</React.Fragment>
							))}
					</>
				}
			</MenuOverflow>
		</MenuRoot>
	);
}

// eslint-disable-next-line max-len
export const Menu = withCssSelector(React.forwardRef(MenuComponent), MenuOverflow.toString()) as unknown as (<T extends React.Key>(
	props: PropsWithClassName<MenuProps<T>> & { ref?: React.ForwardedRef<HTMLUListElement> }
  ) => ReturnType<typeof MenuComponent>) & ComponentWithCssSelector;
