/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as PIXI from 'pixi.js-legacy';
import { AnimationProvider, ContestAppOptions,
	ContestAppSize,
	Contestant,
	ContestantInApp, Position, ProgressInContest, 
	Renderer } from './types';
import { createPixiApp, updateSpinePosition } from './pixiUtilities';
import _clamp from 'lodash/clamp';
import { AnimationState, Spine } from 'pixi-spine';
import { AnimationScheduler } from './animationScheduler';
import { ANIMATION_SPEED,
	ANIMATION_MIX_DURATION } from './constants';
import _keyBy from 'lodash/keyBy';
import { CaptionsRenderer } from './captionsRenderer';
import { animationsHandler, characterRenderer } from './characterRenderer';

export class ContestApp {
	rendered = false;
	container: HTMLElement | undefined;
	size: ContestAppSize;
	options: ContestAppOptions;
	pixiApp: PIXI.Application | undefined;
	contestants: Record<string, ContestantInApp> = {};
	contestantIndex = 0;
	ongoingAnimations: Record<string, {
		ticker: PIXI.Ticker,
		lastPosition: Position,
	}> = {};
	renderer: Renderer;
	animationProvider: AnimationProvider;
	animationScheduler: AnimationScheduler;
	captionsRenderer: CaptionsRenderer;

	constructor(size: ContestAppSize, 
		options: ContestAppOptions) {
		this.size = size;
		this.options = options;
		this.renderer = characterRenderer;
		this.animationProvider = animationsHandler;
		this.captionsRenderer = new CaptionsRenderer(size, options);
		this.animationScheduler = new AnimationScheduler({ animationSpeed: size.height * ANIMATION_SPEED });
	}

	calculatePosition(progressInContest: ProgressInContest, index: number, spine: Spine): Position {
		const {
			positioner,
			numberOfContestants,
		} = this.options;

		const progress = _clamp(progressInContest.progress, 0, 100);

		const position = positioner(this.size, progress, index, numberOfContestants, spine);
		
		return position;
	}

	renderAndStop() {
		this.pixiApp?.render();
		this.pixiApp?.ticker.stop();
		this.rendered = true;
	}

	addContestant(contestant: Contestant, initialProgress: ProgressInContest, atIndex?: number) {
		const index = atIndex !== undefined ? atIndex : this.contestantIndex++;

		const spine = this.renderer(
			this.pixiApp!,
			contestant,
			this.options.characterMode);

		const scale = this.options.scaler(this.size, this.options.numberOfContestants, index);
		spine.scale.set((scale.flip ? -1 : 1) * scale.scale, scale.scale);

		this.pixiApp!.stage.addChild(spine);
		this.pixiApp?.render();

		const position = this.calculatePosition(initialProgress, index, spine);
		spine.position.set(position.x, position.y);

		const captions = this.captionsRenderer.renderCaptions(contestant, initialProgress, position,
			spine.height, spine.width);

		this.pixiApp!.stage.addChild(captions.nameCaption, captions.scoreCaption);

		this.contestants[contestant.id] = {
			...contestant,
			position: position,
			progress: initialProgress,
			spine,
			index,
			captions,
			initialSpineWidth: spine.width,
		};
	}

	updateContestants(contestants: (Contestant & {
		progress: ProgressInContest
	})[]) {
		const visibleContestants = _keyBy(contestants, 'id');
		const removedContestants: ContestantInApp[] = [];
		for (const contestant of Object.values(this.contestants)) {
			if (!visibleContestants[contestant.id]) {
				removedContestants.push(contestant);
			}
		}
		let progressUpdated = false;
		let replacedIndex = 0;

		for (const contestant of contestants) {
			if (this.contestants[contestant.id]) {
				const contestantProgressUpdated = this.updateProgress(contestant.id, contestant.progress);

				progressUpdated = progressUpdated || contestantProgressUpdated;
			} else {
				const removedContestant = removedContestants[replacedIndex];
				this.removeContestant(removedContestant.id);
				this.addContestant(contestant, contestant.progress, removedContestant.index);
				++replacedIndex;
			}
		}

		if (!progressUpdated && removedContestants.length && !this.animationScheduler.isRunningAnyAnimations()) {
			this.renderAndStop();
		}
	}

	removeContestant(id: string) {
		const contestant = this.contestants[id];
		if (!contestant) {
			return;
		}
		this.animationScheduler.stopAnimation(id);
		contestant.spine.destroy();
		contestant.captions.nameCaption.destroy();
		contestant.captions.scoreCaption.destroy();

		delete this.contestants[id];
	}

	init(container: HTMLElement, onDone: () => void) {
		const {
			height,
			width
		} = this.size;

		const pixiApp = createPixiApp({
			width: width,
			height: height,
			onComplete: () => {
				const canvas = pixiApp.view;
				container.innerHTML = '';
				container.appendChild(canvas);

				onDone?.();
			},
			autoStart: false,
			resolution: 1
		});

		this.container = container;

		this.pixiApp = pixiApp;

		this.options.onInitialRender?.(this.pixiApp!, this.size, this.options);
	}

	destroy() {
		if (!this || !this.rendered) {
			return;
		}
		this.animationScheduler.stopAllAnimations();
		for (const contestant of Object.values(this.contestants)) {
			contestant.spine.destroy();
			contestant.captions.nameCaption.destroy();
			contestant.captions.scoreCaption.destroy();
		}
		this.pixiApp?.destroy(true, true);

		this.pixiApp = undefined;
	}

	rerender(width: number, height: number, onDone: () => void) {
		this.destroy();
		
		this.size.width = width;
		this.size.height = height;
		this.contestants = {};
		this.contestantIndex = 0;
		if (this.container) {
			this.container.innerHTML = '';
		}
		this.init(this.container!, onDone);
	}

	updateContestantPosition(
		contestant: ContestantInApp,
		nextPosition: Position
	) {
		const { spine, captions, initialSpineWidth } = contestant;

		updateSpinePosition(spine, nextPosition);

		this.captionsRenderer.updateCaptionsPosition(captions, nextPosition, initialSpineWidth);
	}

	updateProgress(contestantId: string, progressInContest: ProgressInContest): boolean {
		const { animation } = this.options;

		const contestant = this.contestants[contestantId];
		if (!contestant) {
			return false;
		}

		const { spine, position: currentPosition, progress: currentProgress, index, captions,
			initialSpineWidth } = contestant;

		if (currentProgress.progress === progressInContest.progress 
			&& currentProgress.formattedValue === progressInContest.formattedValue) {
			return false;
		}

		if (!this.pixiApp?.ticker.started) {
			this.pixiApp?.ticker.start();
		}

		if (currentProgress.progress !== progressInContest.progress) {
			const targetPosition = this.calculatePosition(progressInContest, index, spine);
	
			const interrupted = this.animationScheduler.scheduleAnimation(
				contestantId,
				currentPosition,
				targetPosition,
				(nextPosition, ended) => {
					this.updateContestantPosition(contestant, nextPosition);
					if (ended) {
						spine.state.setEmptyAnimation(0, ANIMATION_MIX_DURATION);
						this.onAnimationEnded();
					}
				}
			);
			if (!interrupted) {
				spine.state.setAnimationWith(0, AnimationState.emptyAnimation, true);
				setTimeout(() => {
					spine.state.setAnimation(0, this.animationProvider.getMoveAnimation(animation, contestant), true);
				// eslint-disable-next-line no-magic-numbers
				}, 50);
		
			}
			
			this.contestants[contestantId].position = targetPosition;
		}
		
		this.contestants[contestantId].progress = progressInContest;

		captions.scoreCaption.text = progressInContest.formattedValue || `${progressInContest.progress}%`;

		captions.scoreCaption.updateText(true);

		if (currentProgress.progress === progressInContest.progress) {
			this.captionsRenderer.updateCaptionsPosition(captions, currentPosition, initialSpineWidth);
		}

		return true;
	}

	onAnimationEnded() {
		if (!this.animationScheduler.isRunningAnyAnimations()) {
			setTimeout(() => {
				if (!this.animationScheduler.isRunningAnyAnimations()) {
					this.pixiApp?.ticker.stop();
				}
			// eslint-disable-next-line no-magic-numbers
			}, ANIMATION_MIX_DURATION * 1000 + 50);
		}
	}
}
