import { css } from 'styled-components';
import { ButtonColorDefinition as ButtonVariantDefinition } from './ButtonVariant';
import { ButtonSizeDefinition } from './ButtonSize';

export const basicButtonStyling = css`
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	transition: background 0.16s, border 0.16s, color 0.16s;
	font-family: ${props => props.theme.font};
	position: relative;
	.icon:before {
		vertical-align: baseline;
	}
`;

export const sizeDependantStyling = (hasLabel: boolean, sizeDefinition: ButtonSizeDefinition) => css`
	line-height: ${sizeDefinition.lineHeight}px;
	padding: ${hasLabel
		? `${sizeDefinition.paddingVertical}px ${sizeDefinition.paddingHorizontal}px`
		: `${sizeDefinition.paddingNoLabel}px`};
	gap: ${sizeDefinition.gap}px;
	font-size: ${sizeDefinition.fontSize}px;
	& .icon {
		width: 20px;
		text-align: center;
	}
`;

export const variantStyling = (variantDefinition: ButtonVariantDefinition, textButton: boolean,
	sizeDefinition: ButtonSizeDefinition, active?: boolean) =>
	css`
	${variantDefinition.border ? css`
		border-width: 1px;
		border-style: solid;
	` : css`
		border: none;
	`}
	${active ? css`
		color: ${variantDefinition.color.active};
		background: ${variantDefinition.background.active};
		${variantDefinition.border && css`
			border-color: ${variantDefinition.border.active};
		`}
	` : css`
		color: ${variantDefinition.color.default};
		background: ${variantDefinition.background.default};
		${variantDefinition.border && css`
			border-color: ${variantDefinition.border.default};
		`}
	`}
		
	&:before {
		width: 0;
		content: '';
		background: ${variantDefinition.color.hover};
		position: absolute;
		height: 1px;
		bottom: ${sizeDefinition.paddingVertical - 1}px;
		left: 50%;
		right: ${sizeDefinition.paddingHorizontal}px;
		transition: width 0.16s ease 0s, left 0.16s ease 0s;
	}

	&:hover,
	&.hover {
		color: ${variantDefinition.color.hover};
		background: ${variantDefinition.background.hover};
		${variantDefinition.border && css`
			border-color: ${variantDefinition.border.hover};
		`}
		${textButton && css`
			&:before {
				left: ${sizeDefinition.paddingHorizontal}px;
				width: calc(100% - ${sizeDefinition.paddingHorizontal * 2}px);
			}
		`}
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&[disabled],
	&:disabled {
		opacity: ${variantDefinition.opacity};
		color: ${variantDefinition.color.disabled};
		background: ${variantDefinition.background.disabled};
		${variantDefinition.border && css`
			border-color: ${variantDefinition.border?.disabled};
		`}
	}
	
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: ${variantDefinition.color.active};
		background: ${variantDefinition.background.active};
		${variantDefinition.border && css`
			border-color: ${variantDefinition.border?.active};
		`}
	}
}
`;
