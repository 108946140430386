import React from 'react';
import styled, { css } from 'styled-components';
import { withCssSelector } from '../../shared/withCssSelector';
import { FormInputSize, formInputSizes } from '../../utils/formInputSize';
import { blue, neutral } from '../../themes/colors';

const StyledInput = styled.input<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
	$size: FormInputSize;
}>`
	font-family: ${props => props.theme.font};
	accent-color: ${props => props.theme.form.input.accentColor};
	pointer-events: ${props => props.$disabled ? 'none' : 'auto'};

	background-image: none;
	border: ${props => props.theme.form.border.width}px 
		solid ${props => props.theme.form.input.border};
	${props => props.$hasError && css`
		border-color: ${props.theme.form.input.error.border};
		box-shadow: ${props.theme.form.input.error.shadow};
	`}
	border-radius: ${props => props.theme.form.border.radius}px;

	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;	

	${({ $disabled, theme }) => $disabled && css`
		opacity: ${theme.form.disabled.opacity};
	`}

	&[type='radio'],
	&[type='range'],
	&[type='checkbox'] {
		height: 16px;
		width: 16px;
		cursor: pointer;
		border-color: ${neutral['300']};

		:focus {
			border-color: ${blue['300']};
			outline: 0;
			-webkit-box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
			box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
		}
	}

	&[type='range'] {
		display: block;
		width: 100%;
	}

	&[type='time'],
	&[type='date'],
	&[type='text'],
	&[type='datetime'],
	&[type='email'],
	&[type='file'],
	&[type='month'],
	&[type='week'],
	&[type='url'],
	&[type='number'],
	&[type='password'],
	&[type='search'],
	&[type='submit'],
	&[type='tel'] {
		display: block;
		width: 100%;
		height: ${props => props.theme.form.input.size[props.$size].height}px;
		padding: ${props => props.theme.form.input.size[props.$size].padding};
		font-size: ${props => props.theme.form.input.size[props.$size].fontSize}px;
		line-height: 1.42857143;
		color: ${props => props.theme.form.input.color};
		background-color: #fff;
		background-image: none;

		:focus {
			border-color: ${props => props.theme.form.input.focus.border};
			box-shadow: ${props => props.theme.form.input.focus.shadow};
			outline: 0;
		}

		::placeholder {
			color: ${props => props.theme.form.input.placeholderColor};
		}

		${({ $disabled, theme }) => $disabled && css`
			background: ${theme.form.disabled.background};
		`}
	}
`;

export type InputProps = {
	hasError?: boolean | undefined,
	size?: FormInputSize | undefined
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>

export const Input = withCssSelector(React.forwardRef<HTMLInputElement, InputProps>(
	function Input(
		{
			hasError,
			disabled,
			type = 'text',
			tabIndex,
			readOnly,
			size = formInputSizes.default,
			...props
		},
		ref
	) {
		return <StyledInput
			ref={ref}
			type={type}
			$hasError={hasError}
			$disabled={disabled}
			$size={size}
			tabIndex={disabled ? -1 : tabIndex}
			readOnly={disabled ? true : readOnly}
			{...props}
		/>;
	}
), StyledInput.toString());
