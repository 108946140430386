import { Icon } from '../icon';
import { ItemLabel, MenuItemRoot } from '../dropdown/Menu.styled';
import { ButtonProps, LinkButtonProps } from '../button';

type MenuItemProps = LinkButtonProps | ButtonProps

function isLink(item: MenuItemProps): item is LinkButtonProps {
	return (item as LinkButtonProps).href !== undefined;
}

export function DropdownToggleMenuItem(props: MenuItemProps) {
	const { icon, disabled, label, onClick } = props;
	const danger = props.variant === 'danger';
	return <MenuItemRoot
		{...props}
		$disabled={disabled}
		as={isLink(props) ? 'a' : 'button'}
		{...(!disabled && onClick)}
		$danger={danger}
	>
		{icon && <Icon icon={icon} />}
		<ItemLabel $danger={danger} $disabled={disabled}>{label}</ItemLabel>
	</MenuItemRoot>;
}
