import styled from 'styled-components';
import { Icon } from '../icon';

export const DropdownValueIcon = styled(Icon)`
  font-size: 14px;
  flex: 0 1;
`;

export const DropdownValue = styled.span`
  font-size: 14px;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DropdownPlaceholder = styled.span`
  color: ${props => props.theme.form.input.placeholderColor};
  font-size: 14px;
  font-weight: 400;
  flex: 1;
`;

export const DropdownTextInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  height: 20px;
  padding: 0;
  font-weight: 400;

  &::placeholder {
    color: ${props => props.theme.form.input.placeholderColor};
    opacity: 1;
  }
`;

export const DropdownSearchIcon = styled(Icon)`
  flex: 0 1 auto;
  color: ${props => props.theme.form.dropdown.chevron.color};
  align-self: center;
`;

