export const avatarKinds = [
	'astronaut',
	'human',
] as const;

export type AvatarTypeConfig = HumanAvatar | AstronautAvatar

export type AvatarSettings = {
	kind: AvatarKind;
	showInRocket?: boolean;
	} & Record<AvatarKind, AvatarTypeConfig>

export type AvatarKind = typeof avatarKinds[number];

export type HumanAvatar = {
	skin: SkinTone;
	face: {
		feature?: FacialFeature;
		featureColor?: HairColor;
	},
	hair: {
		type?: HairType;
		color?: HairColor;
	},
	tshirt: ClothesColor;
	shorts: ClothesColor;
	shipColor: SpaceColor;
};

export type AstronautAvatar = {
	shipColor: SpaceColor;
	suitColor: SpaceColor;
};

export const skinTones = [
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
] as const;

export type SkinTone = typeof skinTones[number];

export const facialFeatures = [
	'beard',
	'moustache',
	'freckles',
] as const;

export type FacialFeature = typeof facialFeatures[number];

export const hairTypes = [
	'stubble',
	'short',
	'medium',
	'long',
] as const;

export type HairType = typeof hairTypes[number];

export const hairColors = [
	'blue',
	'dark',
	'dark_brown',
	'light_brown',
	'orange',
	'pink',
	'red',
	'violet',
	'white',
	'yellow',
] as const;

export type HairColor = typeof hairColors[number];

export const clothesColors = [
	'blue_1',
	'blue_2',
	'dark_blue',
	'light_grey',
	'dark_grey',
	'light_violet',
	'dark_violet',
	'green',
	'logo',
	'orange',
	'pink',
	'strip',
	'white',
	'yellow',
] as const;

export type ClothesColor = typeof clothesColors[number];

export const spaceColors = [
	'blue_1',
	'blue_2',
	'dark_blue',
	'dark_grey',
	'green',
	'light_grey',
	'orange',
	'pink_1',
	'pink_2',
	'red',
	'violet_1',
	'violet_2',
	'white',
	'yellow',
] as const;

export type SpaceColor = typeof spaceColors[number];

export const characterAnimations = [
	'climb',
	'walk',
	'rocket'
] as const;

export type CharacterAnimation = typeof characterAnimations[number];

export const characterMode = [
	'figure',
	'ship'
] as const;

export type CharacterMode = typeof characterMode[number];
