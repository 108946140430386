import { Spine } from 'pixi-spine';
import { ContestAppOptions, ContestAppSize, Position } from '../app/types';
import { CharacterAnimation, CharacterMode } from '../types';
import * as PIXI from 'pixi.js-legacy';

const CHARACTER_SCALE = 0.0007;
const LEFT_SPACING_RATIO = 0.6;
const RIGHT_SPACING_RATIO = 0.6;
const VERTICAL_ADJUSTMENT = 0.01057142857143;

const EDGE_MARGIN = 20;

const HALF_STEP = 0.5;
const DIVIDER_COLOR = 0xb8c4d5;
const DIVIDER_WIDTH = 4;
const DIVIDER_OPACITY = 0.2;

function positionCaptionOnRight(position: Position,
	textWidth: number, spineWidth: number): boolean {

	return position.x - spineWidth * LEFT_SPACING_RATIO - textWidth <= EDGE_MARGIN;
}

export const spaceContestDefinition = {
	image: 'https://files.static.plecto.com/contest/backgrounds/space-f6d64e9f93.png',
	animation: 'rocket' as CharacterAnimation,
	characterMode: 'ship' as CharacterMode,
	positioner: (appSize: ContestAppSize, progress: number, index: number, numberOfCharacters: number,
		spine: Spine): Position => {
		const { width, height } = appSize;
		const margin = EDGE_MARGIN + (spine.width / 2);
		const x = margin + (width - 2 * margin) * (progress / 100);

		const heightPart = height / numberOfCharacters;

		const y = 
			heightPart * (index + HALF_STEP);

		return {
			x,
			y,
		};
	},
	scaler: (appSize: ContestAppSize, numberOfCharacters: number) => {
		const { height } = appSize;

		return {
			scale: (CHARACTER_SCALE * height / numberOfCharacters),
			flip: false,
		};
	},
	captionPositioner: (appSize: ContestAppSize, position: Position, 
		textWidth: number, spineWidth: number): Position => {

		const onTheRight = positionCaptionOnRight(position, textWidth, spineWidth);

		const { height } = appSize;
		return {
			x: onTheRight ? position.x + spineWidth * RIGHT_SPACING_RATIO 
				: position.x - spineWidth * LEFT_SPACING_RATIO,
			y: position.y - height * VERTICAL_ADJUSTMENT,
		};
	},
	scorePositioner: (appSize: ContestAppSize, position: Position, textWidth: number, spineWidth: number): Position => {
		const { height } = appSize;
		const onTheRight = positionCaptionOnRight(position, textWidth, spineWidth);

		return {
			x: onTheRight ? position.x + spineWidth * RIGHT_SPACING_RATIO
				: position.x - spineWidth * LEFT_SPACING_RATIO,
			y: position.y - height * VERTICAL_ADJUSTMENT,
		};
	},
	captionAnchor: (_appSize: ContestAppSize, position: Position, textWidth: number, spineWidth: number): Position => {
		const onTheRight = positionCaptionOnRight(position, textWidth, spineWidth);
		return {
			x: onTheRight ? 0 : 1,
			y: 1,
		};
	},
	scoreAnchor: (_appSize: ContestAppSize, position: Position, textWidth: number, spineWidth: number): Position => {
		const onTheRight = positionCaptionOnRight(position, textWidth, spineWidth);

		return {
			x: onTheRight ? 0 : 1,
			y: 0,
		};
	},
	onInitialRender: (app: PIXI.Application, appSize: ContestAppSize, appOptions: ContestAppOptions) => {
		if (appOptions.numberOfContestants <= 1) {
			return;
		}

		const { width, height } = appSize;

		const heightPart = height / appOptions.numberOfContestants;

		for (let index = 1; index < appOptions.numberOfContestants; index++) {
			const lineY = heightPart * 
				index;

			const divider = new PIXI.Graphics();

			app.stage.addChild(divider);
			divider.position.set(0,0);
			divider.lineStyle({
				width: DIVIDER_WIDTH,
				color: DIVIDER_COLOR,
				alpha: DIVIDER_OPACITY
			
			}).moveTo(0, lineY)
				.lineTo(width, lineY);
		}
	}
};
