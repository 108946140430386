import { ContestAppSize, Position } from '../app/types';
import { CharacterAnimation, CharacterMode } from '../types';

const SPACING_RATIO = 0.038057142857143;
const SCORE_SPACING_RATIO = 0.060857142857143;
const CHARACTER_SCALE = 0.000085714285714;

const workingArea = {
	bottomLeft: 0.15,
	bottomRight: 0.85,
	bottomY: 0.75,

	topLeft: 0.42,
	topRight: 0.6,
	topY: 0.39
};

export const mountainContestDefinition = {
	image: 'https://files.static.plecto.com/contest/backgrounds/mountiain-5d987de859.png',
	animation: 'walk' as CharacterAnimation,
	characterMode: 'figure' as CharacterMode,
	positioner: (appSize: ContestAppSize, progress: number, index: number,
		numberOfCharacters: number): Position => {
		const { width, height } = appSize;

		const xLeft = workingArea.bottomLeft
			+ ((workingArea.topLeft - workingArea.bottomLeft) * (progress / 100));

		const xRight = workingArea.bottomRight
			+ ((workingArea.topRight - workingArea.bottomRight) * (progress / 100));

		const spaceToUse = width - (xLeft * width) - ((1 - xRight) * width);
		const interval = numberOfCharacters > 1 ? spaceToUse / (numberOfCharacters - 1) : 0;
		const x = (xLeft * width) + (interval * index);

		const positionOnY = workingArea.bottomY - ((workingArea.bottomY
			- workingArea.topY) * (progress / 100));
		const y = positionOnY * height;

		return {
			x,
			y,
		};
	},
	scaler: (appSize: ContestAppSize, numberOfCharacters: number, index: number) => {
		const { height } = appSize;

		return {
			scale: CHARACTER_SCALE * height,
			flip: index < numberOfCharacters / 2,
		};
	},
	captionPositioner: (appSize: ContestAppSize, position: Position): Position => {
		const { height } = appSize;
		return {
			x: position.x,
			y: position.y + (height * SPACING_RATIO),
		};
	},
	scorePositioner: (appSize: ContestAppSize, position: Position): Position => {
		const { height } = appSize;

		return {
			x: position.x,
			y: position.y - (height * SCORE_SPACING_RATIO),
		};
	},
	captionAnchor: (): Position => {
		return {
			x: 0.5,
			y: 0.5,
		};
	},
	scoreAnchor: (): Position => {
		return {
			x: 0.5,
			y: 0.5,
		};
	},
};
