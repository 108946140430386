import styled from 'styled-components';
import React from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { ButtonColorDefinition, ButtonColors, ButtonVariant } from './ButtonVariant';
import { ButtonSize, ButtonSizeDefinition, ButtonSizes } from './ButtonSize';
import { Icon, IconName } from '../icon';
import { basicButtonStyling, sizeDependantStyling, variantStyling, } from './buttonStyling';

const StyledButton = styled.button`
	display: flex;
	${basicButtonStyling}
`;

const StyledButtonVariant = styled(StyledButton)<{
	$colorDefinition: ButtonColorDefinition;
	$sizeDefinition: ButtonSizeDefinition;
	$hasLabel: boolean;
	$active?: boolean;
	$textButton: boolean;
}>`
	${props => sizeDependantStyling(props.$hasLabel, props.$sizeDefinition)}
	${props => variantStyling(props.$colorDefinition, props.$textButton, props.$sizeDefinition, props.$active)}
`;

export type ButtonProps = {
	size?: ButtonSize,
	variant?: ButtonVariant,
	label?: string,
	icon?: IconName,
	active?: boolean
} & React.ComponentPropsWithoutRef<'button'>

export const Button = withCssSelector(React.forwardRef<HTMLButtonElement, ButtonProps>(
	function Button({
		size = 'default',
		variant = 'default',
		label,
		icon,
		children,
		active,
		...other
	}, ref) {

		const colorDefinition = ButtonColors[variant];
		const sizeDefinition = ButtonSizes[size];

		return <StyledButtonVariant 
			ref={ref}
			$colorDefinition={colorDefinition}
			$sizeDefinition={sizeDefinition}
			$hasLabel={Boolean(label)}
			$textButton={variant === 'ghost'}
			type='button'
			$active={active}
			{...other}
		>
			{icon && <Icon icon={icon} />}
			{label}
			{children}
		</StyledButtonVariant>;
	}
), StyledButton.toString());
