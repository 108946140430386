import { Icon, IconName } from '../icon';

import { ItemLabel, MenuItemRoot } from './Menu.styled';

type MenuItemProps = {
	icon?: IconName;
	label: string;
	selected?: boolean;
	onSelect?: () => void;
	disabled?: boolean;
	value?: string;
	danger?: boolean;
}

export function MenuItem({
	icon,
	label,
	selected,
	onSelect,
	disabled,
	value,
}: MenuItemProps) {
	const onClick = () => !disabled && onSelect?.();
	return <MenuItemRoot onClick={onClick} $selected={selected} $disabled={disabled} data-value={value}>
		{icon && <Icon icon={icon} />}
		<ItemLabel $disabled={disabled}>{label}</ItemLabel>
	</MenuItemRoot>;
}
